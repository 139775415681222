<template>
  <div class="link-holder will-transition">
    <div>
      <div class="d-block link-holder__area" @click="viewLink(linkData)">
        <img
          :src="linkData.siteImage"
          class="will-transition"
          :class="{
            extend: extend,
          }"
        />
        <div class="link-holder__content">
          <span class="text-muted small">{{
            truncateText(linkData.longURL, 20)
          }}</span>
          <h3 class="ana_h5 ana_text-dark mb-2">
            {{ truncateText(linkData.title) }}
          </h3>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between link-holder__footer"
      >
        <p class="ana_paragraph text-muted mb-0">
          Clicks: {{ linkData.clicks }}
        </p>
        <div class="d-flex align-items-center">
          <span class="ana_paragraph--small text-muted px-1" v-if="copied"
            >Copied!</span
          >
          <button
            type="button"
            :disabled="copied"
            class="text-decoration-none ana_paragraph link-holder__action text-muted mb-0 mr-3"
            @click="copyLink(linkData.shortURL)"
          >
            <i class="fas fa-copy"></i>
          </button>
          <a
            href="javascript:void(0)"
            class="text-decoration-none ana_paragraph link-holder__action text-muted mb-0"
            @click="makeFavourite(linkData)"
          >
            <i
              class="bi"
              :class="[
                linkData.isFavourite
                  ? 'bi-heart-fill text--primary'
                  : 'bi-heart',
                { 'animate-heart': floatingHeart },
              ]"
            ></i>
          </a>
        </div>
      </div>
      <div class="linear-loader link-holder__loader" v-if="isLoading"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LinkCard",
  props: {
    link: {
      type: Object,
      default: () => {},
    },
    extend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      linkData: this.link,
      isLoading: false,
      floatingHeart: false,
      copied: false,
    };
  },
  methods: {
    async makeFavourite(link) {
      if (this.isLoading) {
        return;
      }
      const id = link._id;
      this.isLoading = true;
      try {
        const { data } = await axios.put(`my-links/favourite/${id}`, {
          isFavourite: !link.isFavourite,
        });
        this.linkData.isFavourite = data.link.isFavourite;
        this.floatingHeart = true;
        setTimeout(() => {
          this.floatingHeart = false;
        }, 3000);
        this.$emit("favouriteUpdated", data.link);
      } catch (error) {
        console.log(error);
        this.$toast.error("Error updating link. Please try again");
      } finally {
        this.isLoading = false;
      }
    },
    viewLink(link) {
      this.$router.push(`/dash/link/${link._id}/${link.slug}`);
    },
    truncateText(title, maxLength = 22) {
      if (title && title.length > maxLength) {
        return title.substring(0, maxLength) + "...";
      }
      return title;
    },
    copyLink(url) {
      navigator.clipboard.writeText(url);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>

<style>
.bi-heart-fill.animate-heart {
  display: block;
  animation: floatHeart 3s ease;
}

@keyframes floatHeart {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0.7;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>
