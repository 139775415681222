var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"link-holder will-transition"},[_c('div',[_c('div',{staticClass:"d-block link-holder__area",on:{"click":function($event){return _vm.viewLink(_vm.linkData)}}},[_c('img',{staticClass:"will-transition",class:{
          extend: _vm.extend,
        },attrs:{"src":_vm.linkData.siteImage}}),_c('div',{staticClass:"link-holder__content"},[_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s(_vm.truncateText(_vm.linkData.longURL, 20)))]),_c('h3',{staticClass:"ana_h5 ana_text-dark mb-2"},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.linkData.title))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between link-holder__footer"},[_c('p',{staticClass:"ana_paragraph text-muted mb-0"},[_vm._v(" Clicks: "+_vm._s(_vm.linkData.clicks)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.copied)?_c('span',{staticClass:"ana_paragraph--small text-muted px-1"},[_vm._v("Copied!")]):_vm._e(),_c('button',{staticClass:"text-decoration-none ana_paragraph link-holder__action text-muted mb-0 mr-3",attrs:{"type":"button","disabled":_vm.copied},on:{"click":function($event){return _vm.copyLink(_vm.linkData.shortURL)}}},[_c('i',{staticClass:"fas fa-copy"})]),_c('a',{staticClass:"text-decoration-none ana_paragraph link-holder__action text-muted mb-0",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.makeFavourite(_vm.linkData)}}},[_c('i',{staticClass:"bi",class:[
              _vm.linkData.isFavourite
                ? 'bi-heart-fill text--primary'
                : 'bi-heart',
              { 'animate-heart': _vm.floatingHeart },
            ]})])])]),(_vm.isLoading)?_c('div',{staticClass:"linear-loader link-holder__loader"}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }