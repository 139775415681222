<template>
  <section>
    <div class="app-wrapper">
      <OverviewHeader :links="links" :linksCount="linksCount" />
      <div
        class="mt-3 mb-3 d-flex align-items-center justify-content-center py-5"
        v-if="isLoading"
      >
        <div class="loader"></div>
      </div>
      <div class="container" v-if="!isLoading">
        <div class="col-lg-10 mx-auto p-0 app-wrapper__content">
          <EmptyLink v-if="links.length == 0" />
          <div class="my-4 mb-5" v-if="links.length > 0">
            <div
              class="py-4 d-flex align-items-center justify-content-between w-100"
            >
              <h3 class="ana_h3 ana_text-dark mb-0">
                Recent Links ({{ recentLinks.length }})
              </h3>
              <div class="d-flex align-items-center">
                <router-link
                  to="/dash/YourLinks/Create"
                  class="ana-btn ana-btn-dash ana-btn__fill"
                  >Create New
                </router-link>
              </div>
            </div>
            <div class="link-area row p-2">
              <div
                v-for="link in recentLinks"
                class="w-100 p-2"
                :key="link._id"
                :class="{ 'col-lg-4': layout == 'grid' }"
              >
                <div>
                  <LinkCard :link="link" />
                </div>
              </div>
            </div>
            <div class="mt-4">
              <router-link
                to="/dash/YourLinks"
                class="ana-btn ana-btn-dash ana-btn__outline"
              >
                View All Links
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import LinkCard from "@/components/dash/LinkCard";
import OverviewHeader from "@/components/dash/Overview/OverviewHeader";
import EmptyLink from "@/components/dash/Links/EmptyLink";
export default {
  components: {
    LinkCard,
    OverviewHeader,
    EmptyLink,
  },
  metaInfo: {
    title: "Overview - Ana app",
  },
  data() {
    return {
      links: [],
      recentLinks: [],
      linkList: {},
      linksCount: 0,
      notVerified: true,
      totalClicks: 0,
      layout: "grid",
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  async mounted() {
    try {
      this.isLoading = true;
      const res = await axios.get("my-links");
      let fetchedLinks = res.data.links;
      this.linksCount = res.data.totalLinks;
      this.links = fetchedLinks;
      this.recentLinks = this.links.slice(0, 9);
    } catch (error) {
      console.error(error);
    } finally {
      if (this.user.isVerified) {
        this.notVerified = false;
      }
      this.isLoading = false;
    }
  },
};
</script>
<style></style>
