<template>
  <div class="container">
    <div class="col-lg-10 mx-auto app-header">
      <div class="w-100">
        <div class="mb-3">
          <div v-if="notVerified">
            <p class="text-white">
              {{ verifyMessage }}
              <a href="#!" class="ana-link" @click="resendVerification"
                >Resend Link</a
              >
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <h3 class="ana_h3 text-white mb-0">Hi {{ user.firstName }}</h3>
        </div>
        <p class="ana_paragraph text-white-50">How are you today?</p>
        <div class="row col-lg-6 align-items-center">
          <div class="py-3 col-6 px-0">
            <p class="ana_paragraph text-white-50 mb-0">Links created</p>
            <h1 class="ana_1 text-white">
              {{ linksCount }}
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "OverviewHeader",
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    linksCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      notVerified: true,
      verifyMessage:
        "You need to verify your account. Please follow the link sent to your email",
    };
  },
  mounted() {
    if (this.user.isVerified) {
      this.notVerified = false;
    }
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  methods: {
    resendVerification() {
      const user = {
        id: this.user._id,
        email: this.user.email,
      };
      axios
        .post("user/resendVerifcation", user)
        .then((res) => {
          if (res.data.sent) {
            this.verifyMessage =
              "A verifcation email has been sent to your email.";
          }
        })
        .catch((err) => {
          this.verifyMessage = err.message;
        });
    },
  },
};
</script>

<style></style>
