<template>
  <div class="empty-links text-center py-5 col-lg-6 mx-auto px-0">
    <img src="@/assets/img/app/empty.svg" class="d-block mx-auto my-4" alt="" />
    <h5 class="ana_h5 ana_text-grey">
      {{ emptyMessage }}
    </h5>
    <div
      class="d-flex align-items-center justify-content-center text-center mt-5"
    >
      <router-link
        to="/dash/YourLinks/Create"
        class="ana-btn ana-btn--small ana-btn-dash ana-btn__fill"
        >Create New
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyLinks",
  props: {
    emptyMessage: {
      type: String,
      default: "You have nothing here yet. Proceed to create your first link",
    },
  },
};
</script>

<style></style>
